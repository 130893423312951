.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: none !important;
  border-width: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sectionTitle{
  background-color: #35aed0;
  color: white;
  border-radius: 5px;
  font-size: large;
  height: 40px;
  font-weight:bold;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.sectionTitleGrey{
  background-color: #545454;
  color: white;
  border-radius: 5px;
  font-size: large;
  height: 40px;
  font-weight:bold;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.profileImage{
  max-width: 55% !important;
  border-radius: 50%;
  border-style: solid;
}


.cardSettings{
  width: 95%;
  text-align: center;
  border-style: solid;;
}

.h1Title{
  color: white;
  font-weight:bolder;
  font-size: xx-large;
  width: 100%; 
  text-align: center; 
  border-bottom: 5px solid white; 
  line-height: 0.1em;
  margin: 10px 0 20px; 
}


h1 span { 
  background: #35aed0;
  padding:0px 30px; 
}

.h2Subtitle{
  color: white;
  font-weight:bolder;
  font-size: large;
}
a {
  text-decoration: none;
  color: white !important;
  text-decoration: underline;

}

a:hover {
  color: rgb(115, 255, 0) !important;
}

.header{
  background-color: #35aed0;
  padding-top: 3%;
  padding-bottom: 2%;
  
}

.contactBar{
  background-color: #545454;;
  color: white;
  font-size: 15px;
  height: 100%;
  margin-bottom: 2%;
  height: 65px;
  font-weight:bold;
}



.justifyContent{
  text-align: justify;
}

@media (max-width: 544px)  {  
  .h1Title{
    font-size: x-large;
  }
  .contactBar{
    font-size: small;
    height: 90px;
   }
   .h2Subtitle{
    font-size: medium;
  }
}

@media (max-width: 544px){
  .FiveColTable{
    display: none;
  }
  
}

@media (min-width: 544px){
  .ThreeColTable{
    display: none;
  }
  
}

.SmallskillHTML{
  max-width: 100%;
  height: 72px;
}
.SmallskillImage{
  max-width: 100%;
  height: 80px;
}

.SmallskillReactjs{
  max-width: 100%;
  height: 90px;
}

.SmallskillGithub{
  max-width: 100%;
  height: 59px;
}

.SmallskillAPI{
  max-width: 100%;
  height: 70px;
}

.SmallskillJS{
  max-width: 100%;
  height: 70px;
}

.SmallskillAWS{
  max-width: 100%;
  height: 40px;
}


.SmallskillCSS{
  max-width: 100%;
  height: 80px;
}

/**/
.skillHTML{
  max-width: 100%;
  height: 85px;
}
.skillImage{
  max-width: 100%;
  height: 85px;
}

.skillReactjs{
  max-width: 100%;
  height: 90px;
}

.skillGithub{
  max-width: 100%;
  height: 75px;
}

.skillAPI{
  max-width: 110%;
  height: 85px;
}

.skillJS{
  max-width: 100%;
  height: 70px;
}

.skillAWS{
  max-width: 100%;
  height: 70px;
}


.skillCSS{
  max-width: 100%;
  height: 85px;
}

.rightAligned{
  text-align: left;
}

.imageBorder{
  border-style: solid;
  max-width: 80%;
  max-height: 80%;
margin: auto;
}

.card{
  margin-bottom: 20px;
}

.InlineskillImage{
  max-width: 100%;
  height: 30px;
}

.projectTitle{
  color: black !important;
  text-decoration: underline;
}

.imgCertificates{
  max-width: 100%;
}